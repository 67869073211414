var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-row-container" },
    [
      _c("v-row", { staticClass: "ml-0 mt-3 mb-3" }, [
        _c("div", { staticClass: "row__icon" }),
        _c("div", { staticClass: "row__title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "v-row",
        { staticClass: "image-row" },
        _vm._l(_vm.imageArray, function (image) {
          return _c(
            "v-card",
            { key: image.id, staticClass: "image-card elevation-0" },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.localOrRemoteURL(image),
                  "aspect-ratio": image.imageAspectRatio,
                  height: _vm.imageDimensions.height,
                  width: _vm.imageDimensions.width,
                  contain: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.onImageClick(image)
                  },
                  error: function ($event) {
                    return _vm.onImageError(image)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "placeholder",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            {
                              staticClass: "fill-height ma-0",
                              attrs: { align: "center", justify: "center" },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "#93BD20" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "v-card-text",
                {
                  staticClass: "text-center image-label",
                  style: { maxWidth: _vm.imageDimensions.width + "px" },
                },
                [_vm._v(_vm._s(image.display))]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }