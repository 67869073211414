<template>
  <EditingModal size="md" class="add-image-modal">
    <AlertDialog title="Add or Edit Photo" :onExit="() => this.$emit('cancel')" :actions="actions">
      <ImageLoader @crop="onCrop" :imageInfo="selectedImageInfo" />
    </AlertDialog>
  </EditingModal>
</template>

<script>
import { imageUploadInfo,generateImageFileName } from "helpers/images.js";
import ImageLoader from "components/imagemanager/image_loader.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            selectedImageBase64: undefined,
            selectedImageFileName: undefined
        };
    },
    components: {
        ImageLoader,
        EditingModal,
        AlertDialog
    },
    props: {
        imageTypeKey: {
            type: String
        },
    },
    computed: {
        selectedImageInfo() {
            if (this.imageTypeKey == undefined) return {};
            return imageUploadInfo()[this.imageTypeKey];
        },
        actions() {
            return [
                {
                    type: "faded",
                    display: "Cancel",
                    run: this.cancel
                },
                {
                    type: this.doneEnabled ? "green" : "faded",
                    display: "Done",
                    noIcon: true,
                    disabled: !this.doneEnabled,
                    run: this.done
                }
            ];
        },
        doneEnabled() {
            return this.selectedImageBase64 != undefined;
        },
        ...mapGetters(["store"])
    },
    methods: {
        onCrop({ fileName, imageBase64 }) {
            this.selectedImageBase64 = imageBase64;
            this.selectedImageFileName = fileName;
        },
        cancel() {
            this.$emit("cancel");
        },
        done() {
            this.$emit('upload', {
                fileName: this.selectedImageFileName,
                imageBase64: this.selectedImageBase64
            })
        }
    }
};
</script>

<style>
</style>
