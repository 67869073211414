  <template>
  <v-container class="pa-0 mb-3">
    <v-row class="ml-0 mt-3 mb-0" >
      <div class="row__icon"></div>
      <div class="row__title">{{ title }}</div>
      <!-- </v-flex> -->
    </v-row>
    <v-row class="image-row">
      <v-card class="image-card elevation-0">
        <v-card-title class="pl-0 pr-0 pb-0 mb-2 justify-center">
          <div v-if="canEdit" class="image--edit" @click.stop="onEditCategoryImage"></div>
          <div v-if="canEdit" class="image--delete" @click.stop="onDeleteCategoryImage"></div>
        </v-card-title>
        <v-img
          :src="categoryImageURL"
          @click="onEditCategoryImage"
          :aspect-ratio="imageInfo.aspectRatio"
          :height="140"
          :width="250"
          contain
          position="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
      <!-- </v-flex> -->
    </v-row>
    <ImageLoaderModal
      v-if="addImageModal"
      :imageTypeKey="imageTypeKey"
      @cancel="() => { this.addImageModal = false }"
      @upload="upload"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
    fullUrlForImageValue,
    imageUploadInfo,
    generateImageFileName
} from "helpers/images";
import ImageLoaderModal from "components/imagemanager/image_loader_modal";

import missing16x19 from "img/image_add_16x9.svg";
import AccessMixin from "mixins/access_helper.js";

export default {
    data() {
        return {
            imageTypeKey: "product_16_9",
            addImageModal: false
        };
    },
    mixins: [AccessMixin],
    props: {
        category: {
            type: Object
        }
    },
    components: {
        ImageLoaderModal
    },
    computed: {
        title() {
            return this.category.display;
        },
        imageInfo() {
            return imageUploadInfo()[this.imageTypeKey];
        },
        categoryImageURL() {
            if (this.category === undefined || this.category.image == "")
                return missing16x19;
            let splits = this.category.image.split("/");
            let lastPath = splits[splits.length - 1];
            if (this.uploadedImages[lastPath] != undefined)
                return this.uploadedImages[lastPath];
            return fullUrlForImageValue(this.category.image);
        },
        ...mapState("ImageUploadStore", ["uploadedImages"]),
        ...mapGetters(["storeImage", "store"])
    },
    methods: {
        onEditCategoryImage() {
            if (!this.canEdit) return
            this.addImageModal = true;
        },
        onDeleteCategoryImage() {
            this.addImageModal = false;
            var payloads = [];

            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;

            let image = {
                inventoryTitleID: this.category.id,
                maxDimensions: imageUploadInfo()[this.imageTypeKey].maxDimensions,
                fileName: "",
                storeWaypointID,
                airportIdent,
                imageKey: this.imageTypeKey,
                imageTypeID: 5
            };

            this.uploadCategoryImage(image)
                .then(() => {
                    return this.getActiveStore(this.$route.params.store).then(() => {
                        this.toast(
                            `Category image uploaded. Images may take up to 1 minute to sync with server.`,
                            "success"
                        );
                    });
                })
                .catch(error => {
                    this.toast("Error saving images.", "error");
                });
        },
        upload({ fileName, imageBase64 }) {
            this.addImageModal = false;
            var payloads = [];

            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;

            let image = {
                inventoryTitleID: this.category.id,
                maxDimensions: imageUploadInfo()[this.imageTypeKey].maxDimensions,
                imageBase64: imageBase64,
                imageName: generateImageFileName(
                    this.category.id,
                    this.imageTypeKey,
                    fileName
                ),
                storeWaypointID,
                airportIdent,
                imageKey: this.imageTypeKey,
                imageTypeID: 6
            };

            this.uploadCategoryImage(image).then(() => {
                return this.getActiveStore(this.$route.params.store).then(() => {
                    this.toast(
                        `Category image uploaded. Images may take up to 1 minute to sync with server.`,
                        "success"
                    );
                });
            });
        },
        ...mapActions(["getActiveStore"]),
        ...mapActions("ImageUploadStore", ["uploadCategoryImage"])
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
.image-card {
  padding: 2px;
  background-color: transparent !important;
}

.image-row {
  margin-left: 65px !important;
}
.image {
  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    width: 20px;
    height: 20px;
    padding: 5px;
  }
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "image_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
    margin-left: 18px;
  }
}
</style>
  