  <template>
    <v-container>
      <v-row>
        <v-col>
          <v-card max-width="374">
            <v-card-title class="image--title pl-5 pr-5 pb-0 mb-2 justify-space-between">
              {{ title }} <v-btn
                @click="onEditStoreImage"
                v-if="canEdit"
                text
                icon
            >
              <v-icon class="image--edit">fas fa-lock</v-icon>
            </v-btn>
            </v-card-title>
            <v-img :src="storeImageUrl" height="150" contain>
              <template v-slot:placeholder>
                <v-row no-gutters class="ma-0 fill-height" align="center" justify="center">
                  <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-spacer/>
      <ImageLoaderModal
          v-if="addImageModal"
          :imageTypeKey="imageTypeKey"
          @cancel="() => { this.addImageModal = false }"
          @upload="upload"
      />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
    fullUrlForImageName,
    imageUploadInfo,
    generateImageFileName
} from "helpers/images";
import ImageLoaderModal from "components/imagemanager/image_loader_modal";

import missing1x1 from "img/image_add_1x1.svg";
import AccessMixin from "mixins/access_helper.js";

export default {
    data() {
        return {
            imageTypeKey: "store_image",
            addImageModal: false,
            uploadedStoreImage: undefined
        };
    },
    mixins: [AccessMixin],
    components: {
        ImageLoaderModal
    },
    computed: {
        title() {
            return "Store Image";
        },
        storeImageUrl() {
            if (this.uploadedStoreImage != undefined) return this.uploadedStoreImage;
            if (this.storeImage === undefined) return "";
            if (this.storeImage == "defaultStoreImage.jpg") return missing1x1;
            return fullUrlForImageName(this.storeImage);
        },
        ...mapGetters(["storeImage", "store"])
    },
    methods: {
        onEditStoreImage() {
            this.addImageModal = true;
        },
        onDeleteStoreImage() {
            // this.addImageModal = false;
            // var payloads = [];

            // let storeID = this.store.storeID

            // let image = {
            //   storeWaypointID,
            //   maxDimensions: imageUploadInfo()[this.imageTypeKey].maxDimensions,
            //   imageBase64: "",
            //   fileName: ""
            // };

            // this.uploadStoreImage(image)
            //   .then(() => {
            //     this.uploadedStoreImage = imageBase64;
            //     this.toast(
            //       `Store image uploaded. Images may take up to 1 minute to sync with server.`,
            //       "success"
            //     );
            //   })
            //   .catch(error => {
            //     this.toast("Error saving images.", "error");
            //   });
        },
        upload({ fileName, imageBase64 }) {
            this.addImageModal = false;
            var payloads = [];

            let storeID = this.store.storeID

            let image = {
                storeID,
                maxDimensions: imageUploadInfo()[this.imageTypeKey].maxDimensions,
                imageBase64: imageBase64,
                fileName: generateImageFileName(
                    storeID,
                    this.imageTypeKey,
                    fileName
                )
            };

            this.uploadStoreImage(image)
                .then(() => {
                    this.uploadedStoreImage = imageBase64;
                    this.toast(
                        `Store image uploaded. Images may take up to 1 minute to sync with server.`,
                        "success"
                    );
                })
                .catch(error => {
                    this.toast("Error saving images.", "error");
                });
        },
        ...mapActions("ImageUploadStore", ["uploadStoreImage"])
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
.image-card {
  padding: 2px;
  background-color: transparent !important;
}

.image {
  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    cursor: pointer;
    background-size: 20px;
    width: 20px;
    height: 20px;
  }
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "image_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
    margin-left: 18px;
  }
}
</style>
