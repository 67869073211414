var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 mb-3" },
    [
      _c("v-row", { staticClass: "ml-0 mt-3 mb-0" }, [
        _c("div", { staticClass: "row__icon" }),
        _c("div", { staticClass: "row__title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "v-row",
        { staticClass: "image-row" },
        [
          _c(
            "v-card",
            { staticClass: "image-card elevation-0" },
            [
              _c(
                "v-card-title",
                { staticClass: "pl-0 pr-0 pb-0 mb-2 justify-center" },
                [
                  _vm.canEdit
                    ? _c("div", {
                        staticClass: "image--edit",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onEditCategoryImage.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.canEdit
                    ? _c("div", {
                        staticClass: "image--delete",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onDeleteCategoryImage.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _c("v-img", {
                attrs: {
                  src: _vm.categoryImageURL,
                  "aspect-ratio": _vm.imageInfo.aspectRatio,
                  height: 140,
                  width: 250,
                  contain: "",
                  position: "center",
                },
                on: { click: _vm.onEditCategoryImage },
                scopedSlots: _vm._u([
                  {
                    key: "placeholder",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          {
                            staticClass: "fill-height ma-0",
                            attrs: { align: "center", justify: "center" },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "#93BD20" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addImageModal
        ? _c("ImageLoaderModal", {
            attrs: { imageTypeKey: _vm.imageTypeKey },
            on: {
              cancel: () => {
                this.addImageModal = false
              },
              upload: _vm.upload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }