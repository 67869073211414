<template>
  <div class="image-row-container">
    <!-- <v-container pa-0 mb-3> -->
    <v-row class="ml-0 mt-3 mb-3" >
      <div class="row__icon"></div>
      <div class="row__title">{{ title }}</div>
      <!-- </v-flex> -->
    </v-row>
    <v-row class="image-row"  >
      <v-card class="image-card elevation-0" v-for="image in imageArray" :key="image.id">

        <v-img
          :src="localOrRemoteURL(image)"
          :aspect-ratio="image.imageAspectRatio"
          :height="imageDimensions.height"
          :width="imageDimensions.width"
          contain
          @click="onImageClick(image)"
          @error="onImageError(image)"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="#93BD20"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-text
          class="text-center image-label"
          :style="{maxWidth: imageDimensions.width + 'px'}"
        >{{ image.display }}</v-card-text>
      </v-card>
    </v-row>
    <!-- <AddImageModal v-if="addImageModal" @close="onAddClose"/> -->
    <!-- </v-container> -->
  </div>
</template>

<script>
import {
    fullUrlForImageValue,
    aspectRatioForImageKey,
    imagesPageDisplayStringForImageKey,
    dimensionsForImageKey,
    missingImageForImageKey
} from "helpers/images.js";

import { mapState } from "vuex";
import AccessMixin from "mixins/access_helper.js"

export default {
    data() {
        return {};
    },
    mixins: [AccessMixin],
    components: {},
    props: {
        products: {
            type: Array
        },
        imageTypeKey: {
            type: String,
            required: true
        }
    },
    computed: {
        title() {
            return imagesPageDisplayStringForImageKey(this.imageTypeKey);
        },
        imageArray() {
            if (this.products == undefined) return [];

            return this.products.map(el => {
                "inventoryItemImageName";
                let imageName =
          this.imageTypeKey == "img_asset_384x216"
              ? el.inventoryItemImageName
              : el.imageV2[this.imageTypeKey];
                let hasImage = imageName != "";
                return {
                    display: el.inventoryItemName,
                    imageUrl:
            imageName == ""
                ? missingImageForImageKey(this.imageTypeKey)
                : fullUrlForImageValue(imageName),
                    imageAspectRatio: aspectRatioForImageKey(this.imageTypeKey),
                    id: el.inventoryItemID,
                    hasImage,
                    product: true,
                    imageName: imageName
                };
            });
        },
        imageDimensions() {
            return dimensionsForImageKey(this.imageTypeKey);
        },
        ...mapState("ImageUploadStore", ["uploadedImages"])
    },
    methods: {
        localOrRemoteURL(image) {
            let splits = image.imageUrl.split("/");
            let lastPath = splits[splits.length - 1];
            if (this.uploadedImages[lastPath] != undefined)
                return this.uploadedImages[lastPath];
            return image.imageUrl;
        },
        onAddClose() {
            this.addImageModal = false;
        },
        calcImageWidth(height, aspectRatio) {
            return height * aspectRatio;
        },
        onImageClick(image) {
            if (!image.product) return;
            if (!image.hasImage && this.canEdit) {
                this.$emit("add", {
                    imageTypeKey: this.imageTypeKey,
                    productID: image.id
                });
            } else {
                this.$emit("details", {
                    productID: image.id,
                    imageTypeKey: this.imageTypeKey
                });
            }
        },
        onImageError(image) {},
        onEditImage(image) {},
        onDeleteImage(image) {},
        onError(product) {
        },
        onLoaderUpload(imageBase64) {}
    },
    name: "ImagesExpandedSubrow"
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.image-label {
  padding: 0px;
}

.image-row-container {
  padding-top: 15px;
  padding-bottom: 15px;
}
.title-label {
  flex-grow: 0 !important;
  height: 16px;
  width: 47px;
  color: #555555;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.image-card {
  padding: 2px;
  background-color: transparent !important;
}

.image-row {
  overflow: auto;
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 0 !important;
  margin-left: 50px !important;
  max-width: 1500px;
}
.add-new {
  vertical-align: middle;
  display: flex;
  cursor: pointer;

  &__img {
    margin-left: 64px;
    width: spacing(sm);
    height: spacing(sm);
    background-position: right center;
    background-color: $brand_color;
    @include mask-image("", "plus.svg", "../../img/");
    mask-size: 15px 19px;
  }

  &__label {
    height: 21px;
    width: 115px;
    padding-left: 3px;
    color: #72980a;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
  }
}
.image {
  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "image_logo.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);
    margin-left: 18px;
  }
}
</style>

