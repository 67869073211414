var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: _vm.title },
      }),
      _c("StoreImageRow"),
      _vm.categories.length
        ? _c("v-data-table", {
            attrs: {
              items: _vm.categories,
              headers: _vm.headers,
              expanded: _vm.expanded,
              "disable-pagination": true,
              dense: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-data",
                  fn: function () {
                    return [_c("div", { attrs: { id: "no-data" } })]
                  },
                  proxy: true,
                },
                {
                  key: "item",
                  fn: function ({ expand, item, isExpanded }) {
                    return [
                      _c(
                        "tr",
                        {
                          staticStyle: { "max-width": "100%" },
                          on: {
                            click: function ($event) {
                              return expand(!isExpanded)
                            },
                          },
                        },
                        [
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-row justify-start align-center",
                              },
                              [
                                _c("span", {
                                  staticClass: "row__icon",
                                  class: { "row__icon--active": !isExpanded },
                                }),
                                _c("span", { staticClass: "row__title" }, [
                                  _vm._v(_vm._s(item.display)),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "expanded-item",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        [
                          _c("CategoryImageRow", {
                            attrs: {
                              category: props.item,
                              imageTypeKey: "img_asset_384x216",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "product_1_1",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "product_16_9",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "featured_9_2",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "launch_1_1",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "launch_9_16",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                          _c("SubRow", {
                            attrs: {
                              products: props.item.children,
                              imageTypeKey: "img_asset_384x216",
                            },
                            on: {
                              add: _vm.onSubImageAdd,
                              details: _vm.onSubImageDetails,
                              cancel: _vm.onSubImageModalCancel,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              137646622
            ),
          })
        : _vm._e(),
      _vm.loaderModal
        ? _c("ImageLoaderModal", {
            attrs: { imageTypeKey: _vm.loaderModalTypeKey },
            on: {
              cancel: () => (this.loaderModal = false),
              upload: _vm.onLoaderUpload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }