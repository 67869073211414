var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditingModal",
    { staticClass: "add-image-modal", attrs: { size: "md" } },
    [
      _c(
        "AlertDialog",
        {
          attrs: {
            title: "Add or Edit Photo",
            onExit: () => this.$emit("cancel"),
            actions: _vm.actions,
          },
        },
        [
          _c("ImageLoader", {
            attrs: { imageInfo: _vm.selectedImageInfo },
            on: { crop: _vm.onCrop },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }