// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/edit_logo.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../img/image_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".image-card[data-v-1fef36cf] {\n  padding: 2px;\n  background-color: transparent !important;\n}\n.image-row[data-v-1fef36cf] {\n  margin-left: 65px !important;\n}\n.image--delete[data-v-1fef36cf] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}\n.image--edit[data-v-1fef36cf] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 14px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  padding: 5px;\n}\n.row__title[data-v-1fef36cf] {\n  padding-left: 12px;\n}\n.row__icon[data-v-1fef36cf] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 18px;\n  height: 18px;\n  margin-left: 18px;\n}", ""]);
// Exports
module.exports = exports;
