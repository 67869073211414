var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { "max-width": "374" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "image--title pl-5 pr-5 pb-0 mb-2 justify-space-between",
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _vm.canEdit
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "", icon: "" },
                              on: { click: _vm.onEditStoreImage },
                            },
                            [
                              _c("v-icon", { staticClass: "image--edit" }, [
                                _vm._v("fas fa-lock"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-img", {
                    attrs: {
                      src: _vm.storeImageUrl,
                      height: "150",
                      contain: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "placeholder",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              {
                                staticClass: "ma-0 fill-height",
                                attrs: {
                                  "no-gutters": "",
                                  align: "center",
                                  justify: "center",
                                },
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "#93BD20",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _vm.addImageModal
        ? _c("ImageLoaderModal", {
            attrs: { imageTypeKey: _vm.imageTypeKey },
            on: {
              cancel: () => {
                this.addImageModal = false
              },
              upload: _vm.upload,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }