<template>
  <div>
    <ContextTitle :passedActions="titleBarActions" :title="title" />
    <StoreImageRow />
    <v-data-table
        v-if="categories.length"
        :items="categories"
        :headers="headers"
        :expanded="expanded"
        :disable-pagination="true"
        dense
    >
      <template v-slot:no-data>
        <div id="no-data" />
      </template>
      <template v-slot:item="{expand, item, isExpanded}">
        <tr @click="expand(!isExpanded)" style="max-width: 100%;">
          <td>
            <div class="item-row justify-start align-center">
              <span class="row__icon" :class="{'row__icon--active': !isExpanded}"></span>
              <span class="row__title">{{ item.display }}</span>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="props">
        <div>
          <CategoryImageRow
              :category="props.item"
              imageTypeKey="img_asset_384x216"
              @add="onSubImageAdd"
              @cancel="onSubImageModalCancel"
          />
 
          <SubRow
              :products="props.item.children"
              imageTypeKey="product_1_1"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
          <SubRow
              :products="props.item.children"
              imageTypeKey="product_16_9"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
          <SubRow
              :products="props.item.children"
              imageTypeKey="featured_9_2"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
          <SubRow
              :products="props.item.children"
              imageTypeKey="launch_1_1"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
          <SubRow
              :products="props.item.children"
              imageTypeKey="launch_9_16"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
          <SubRow
              :products="props.item.children"
              imageTypeKey="img_asset_384x216"
              @add="onSubImageAdd"
              @details="onSubImageDetails"
              @cancel="onSubImageModalCancel"
          />
        </div>
      </template>
    </v-data-table>
    <ImageLoaderModal
      v-if="loaderModal"
      :imageTypeKey="loaderModalTypeKey"
      @cancel="() => this.loaderModal = false"
      @upload="onLoaderUpload"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StoreImageRow from "components/imagemanager/store_image_row";
import CategoryImageRow from "components/imagemanager/images_expanded_category_subrow";
import ContextTitle from "components/context_title";
import SubRow from "components/imagemanager/images_expanded_subrow";
import ImageLoaderModal from "components/imagemanager/image_loader_modal.vue";
import {
    imagePartialURLToRouteParam,
    generateImageFileName,
    imageUploadInfo
} from "helpers/images.js";
import AccessMixin from "mixins/access_helper.js";

export default {
    mixins: [AccessMixin],
    data() {
        return {
            headersLength: 2,
            titleBarActions: [],
            rowsPerPageItems: [10, 20, 30, 40, 100],
            pagination: {
                rowsPerPage: 100,
                page: 1
            },
            expand: true,
            expanded: [],

            headers: [
                {
                    sortable: false,
                    text: "",
                    value: "name"
                }
            ],
            title: "Images",
            loaderModal: false,
            loaderModalTypeKey: undefined,
            loaderModalProductID: undefined
        };
    },
    components: {
        StoreImageRow,
        ContextTitle,
        SubRow,
        ImageLoaderModal,
        CategoryImageRow
    },
    computed: {
        categories() {
            return this.productCategories
                .filter(
                    el =>
                        el.inventoryTitleDescription != "SHOW ALL" &&
            el.inventoryTitleDescription.length > 0
                )
                .map(el => {
                    return {
                        id: el.inventoryTitleID,
                        display: el.inventoryTitleDescription,
                        children: el.children,
                        image: el.imageNameWide
                    };
                });
        },
        ...mapGetters(["productCategories", "productsMap", "store"])
    },
    methods: {
        onSubImageAdd(payload) {
            this.loaderModalTypeKey = payload.imageTypeKey;
            this.loaderModalProductID = payload.productID;
            this.loaderModal = true;
        },
        onSubImageModalCancel() {
            this.loaderModalTypeKey = undefined;
            this.loaderModalProductID = undefined;
            this.loaderModal = false;
        },
        onSubImageDetails(image) {
            this.$router.push({
                name: "image-details",
                params: {
                    imageTypeKey: image.imageTypeKey,
                    inventoryItemID: image.productID
                }
            });
        },
        onLoaderUpload({ fileName, imageBase64 }) {
            var payloads = [];
            let inventoryItemID = this.loaderModalProductID;
            let inventoryItemSubID = this.productsMap[inventoryItemID]
                .inventoryItemSubs[0].inventoryItemSubID.inventoryItemSubID;
            let storeWaypointID = this.$route.params.store;
            let airportIdent = this.store.airportIdent;

            payloads.push({
                inventoryItemID,
                inventoryItemSubID,
                storeWaypointID,
                airportIdent,
                imageKey: this.loaderModalTypeKey,
                imageTypeID: imageUploadInfo()[this.loaderModalTypeKey].typeID,
                maxDimensions: imageUploadInfo()[this.loaderModalTypeKey].maxDimensions,
                imageBase64: imageBase64,
                imageName: generateImageFileName(
                    inventoryItemID,
                    this.loaderModalTypeKey,
                    fileName
                )
            });
            this.uploadImageV2(payloads)
                .then(() => {
                    this.toast(
                        `Image uploaded. Images may take up to 1 minute to sync with server.`,
                        "success"
                    );
                    this.getActiveStore(this.$route.params.store);

                    this.loaderModalTypeKey = undefined;
                    this.loaderModalProductID = undefined;
                    this.loaderModal = false;
                })
                .catch(error => {
                    this.toast("Error saving images.", "error");
                });
        },
        ...mapActions("ImageUploadStore", ["uploadImageV2"]),
        ...mapActions(["getActiveStore"])
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

#no-data {
  height: 100%;
  width: 100%;
}

.v-data-table {
  tbody {
    tr:hover:not(.v-datatable__expand-row) {
      background: white;
    }
    tr {
      cursor: pointer;
      td {
        color: #585858 !important;
        height: 41px;
        font-size: 14px;
        padding-left: 30px;
        width: 250px;
      }
    }
  }
}

.row {
  &__title {
    padding-left: spacing(xs);
  }

  &__icon {
    @include background-image("", "images_folder_closed.svg", "../../img/");
    background-size: contain;
    width: spacing(sm);
    height: spacing(sm);

    &--active {
      @include background-image("", "images_folder_closed.svg", "../../img/");
    }
  }

  &--delete {
    @include background-image("", "delete.svg", "../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.item-row {
  height: 41px;
}

.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");
  margin-left: 15px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
</style>
